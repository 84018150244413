import React from 'react';
import { formatNumber } from 'libphonenumber-js';
import spacetime from 'spacetime';
import classes from './UserItem.module.scss';

const UserItem = ({ user, navigateToView, updateUserStatus }) => {
  const onEnableUser = (event) => {
    event.stopPropagation();
    if (!user.is_active) updateUserStatus(user);
  };

  const onDisableUser = (event) => {
    event.stopPropagation();
    if (user.is_active) updateUserStatus(user);
  };

  return (
    <div
      onClick={() => navigateToView(user._id)}
      className={[classes.userContent, classes.cf].join(' ')}
    >
      <div className={[classes.tabRow, classes.usersName].join(' ')}>
        <div className={classes.userImage}>
          <i className={[classes.userIcon, 'far fa-user-circle'].join(' ')}></i>
        </div>
        <p className={classes.uesrDtl}>
          {user.name ? user.name : 'NA'}
          <span className={classes.dateTime}>
            {spacetime(new Date(user.created_at)).format(
              'MMMM d, yyyy h:mm aa'
            )}
          </span>
        </p>
      </div>
      <div className={[classes.tabRow, classes.usersEmail].join(' ')}>
        {user.email ? user.email : 'NA'}
      </div>
      <div className={[classes.tabRow, classes.usersMobile].join(' ')}>
        {(user.mobile && formatNumber(user.mobile, 'INTERNATIONAL')) ||
          user.mobile ||
          'NA'}
      </div>
      <div className={[classes.tabRow, classes.usersGender].join(' ')}>
        {user.gender ? user.gender : 'NA'}
      </div>
      <div className={[classes.tabRow, classes.usersBdate].join(' ')}>
        {user.dob ? spacetime(new Date(user.dob)).format('MM/dd/YYYY') : 'NA'}
      </div>
      <div className={[classes.tabRow, classes.usersHf].join(' ')}>
        {user.task_completes ? user.task_completes.length : 0}
      </div>
      <div className={[classes.tabRow, classes.usersFriend].join(' ')}>
        {user.contacts ? user.contacts.length : 0}
      </div>
      <div className={[classes.tableRow, classes.reqbtnDiv].join(' ')}>
        <button
          onClick={onEnableUser}
          className={[
            classes.commonButton,
            user.is_active ? classes.enable : '',
          ].join(' ')}
          disabled={user.is_active}
          type="button"
        >
          Enable
        </button>
        <button
          onClick={onDisableUser}
          className={[
            classes.commonButton,
            !user.is_active ? classes.disable : '',
          ].join(' ')}
          disabled={!user.is_active}
          type="button"
        >
          Disable
        </button>
      </div>
    </div>
  );
};

export default UserItem;
