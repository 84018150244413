import spacetime from "spacetime";
import { NotificationManager } from "react-notifications";
import request from "./request";
import apis from "../config/apis";
import { doLogin, getToken } from "./auth";

const jsonHeader = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const formHeader = {};

const generateQueryString = (queryString, filterParams) => {
	for (let key in filterParams) {
		if (filterParams[key]) {
			queryString += '&' + key + '=' + encodeURIComponent(filterParams[key])
		}
	}
	return queryString;
}

export const getParsedMsg = (e) => {
  return e ? e.msg || e.message || "" : "";
};

export const verifyEmail = (payload) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await request(apis.auth.verifyEmail, {
        method: "POST",
        headers: jsonHeader,
        body: JSON.stringify(payload),
      });
      return resolve(data);
    } catch (e) {
      return reject(e);
    }
  });

export const login = (payload) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await request(apis.auth.login, {
        method: "POST",
        headers: jsonHeader,
        body: JSON.stringify(payload),
      });
      await doLogin(data.data);
      return resolve(data);
    } catch (e) {
      NotificationManager.error(getParsedMsg(e));
      return reject(e);
    }
  });

export const addHappiness = (payload) =>
  new Promise(async (resolve, reject) => {
    const token = getToken();
    const formData = new FormData();
    formData.append("title", payload.title);
    payload.recommend_to &&
      formData.append("assignees", JSON.stringify(payload.recommend_to));
    payload.execute_date &&
      formData.append(
        "execute_date",
        spacetime(new Date(payload.execute_date)).format("iso-utc")
      );
    payload.help_to &&
      formData.append("help_to", JSON.stringify(payload.help_to));
    payload.description && formData.append("description", payload.description);
    payload.address && formData.append("address", payload.address);
    payload.image && formData.append("image", payload.image);

    try {
      const data = await request(apis.happiness.add, {
        method: "POST",
        headers: { ...formHeader, Authorization: token },
        body: formData,
      });
      NotificationManager.success(getParsedMsg(data));
      return resolve(data);
    } catch (e) {
      NotificationManager.error(getParsedMsg(e));
      return reject(e);
    }
  });

export const updateHappiness = (payload, id) =>
  new Promise(async (resolve, reject) => {
    const token = getToken();
    const formData = new FormData();
    formData.append("title", payload.title);
    payload.recommend_to &&
      formData.append("assignees", JSON.stringify(payload.recommend_to));
    payload.execute_date &&
      formData.append(
        "execute_date",
        spacetime(new Date(payload.execute_date)).format("iso-utc")
      );
    payload.help_to &&
      formData.append("help_to", JSON.stringify(payload.help_to));
    payload.description && formData.append("description", payload.description);
    payload.address && formData.append("address", payload.address);
    payload.image && formData.append("image", payload.image);

    try {
      const data = await request(`${apis.happiness.add}/${id}`, {
        method: "PUT",
        headers: { ...formHeader, Authorization: token },
        body: formData,
      });
      NotificationManager.success(getParsedMsg(data));
      return resolve(data);
    } catch (e) {
      NotificationManager.error(getParsedMsg(e));
      return reject(e);
    }
  });

export const getRecommendUsers = () =>
  new Promise(async (resolve, reject) => {
    try {
      const get_users = await getUsers();
      const users = [];

      get_users.forEach((user) => {
        users.push({
          label: `${user.name} (${user.mobile})`.trim(),
          value: {
            name: user.name,
            mobile: user.mobile,
          },
        });
      });

      return resolve(users);
    } catch (e) {
      return reject(e);
    }
  });

export const getUsers = (page, filter, signal) =>
  new Promise(async (resolve) => {
    const token = getToken();
    let url = `${apis.user.getAll}`;
    const queryString = generateQueryString(`page=${page}`, filter);
    try {
      const data = await request(`${url}?${queryString}`, {
        method: "GET",
        headers: { ...jsonHeader, Authorization: token },
        signal: signal,
      });
      return resolve(data);
    } catch (e) {
      return resolve(e);
    }
  });

export const getUser = (id) =>
  new Promise(async (resolve, reject) => {
    const token = getToken();
    try {
      const data = await request(`${apis.user.getAll}/${id}`, {
        method: "GET",
        headers: { ...jsonHeader, Authorization: token },
      });
      return resolve(data);
    } catch (e) {
      return reject(e);
    }
  });

export const updateUserStatus = (payload) =>
  new Promise(async (resolve, reject) => {
    const token = getToken();
    try {
      const data = await request(`${apis.user.updateStatus}`, {
        method: "PUT",
        headers: { ...jsonHeader, Authorization: token },
        body: JSON.stringify(payload),
      });
      NotificationManager.success(getParsedMsg(data));
      return resolve(data);
    } catch (e) {
      NotificationManager.error(getParsedMsg(e));
      return reject(e);
    }
  });

export const updateTaskVisibility = (taskId) =>
  new Promise(async (resolve, reject) => {
    const token = getToken();
    try {
      const data = await request(`${apis.happiness.updateStatus}/${taskId}`, {
        method: "PUT",
        headers: { ...jsonHeader, Authorization: token },
      });
      NotificationManager.success(getParsedMsg(data));
      return resolve(data);
    } catch (e) {
      NotificationManager.error(getParsedMsg(e));
      return reject(e);
    }
  });

export const getHappinesses = (page, filter, signal) => {
  return new Promise(async (resolve) => {
    const token = getToken();

    let url = `${apis.happiness.getAll}`;
    const queryString = generateQueryString(`page=${page}`, filter);

    try {
      const data = await request(`${url}?${queryString}`, {
        method: "GET",
        headers: { ...jsonHeader, Authorization: token },
        signal,
      });
      return resolve(data);
    } catch (e) {
      return resolve([]);
    }
  });
};

export const getHappiness = (id) =>
  new Promise(async (resolve, reject) => {
    const token = getToken();
    try {
      const data = await request(`${apis.happiness.getAll}/${id}`, {
        method: "GET",
        headers: { ...jsonHeader, Authorization: token },
      });
      return resolve(data);
    } catch (e) {
      return reject(e);
    }
  });

export const getNotifications = (page) =>
  new Promise(async (resolve) => {
    const token = getToken();
    try {
      const data = await request(`${apis.notification.getAll}?page=${page}`, {
        method: "GET",
        headers: { ...jsonHeader, Authorization: token },
      });
      return resolve(data);
    } catch (e) {
      return resolve([]);
    }
  });

export const shareHappiness = (id, share) =>
  new Promise(async (resolve, reject) => {
    if (share.action === "dismissedAction") {
      return resolve();
    }

    if (
      share.activityType &&
      share.activityType.indexOf("CopyToPasteboard") !== -1
    ) {
      return resolve();
    }
    const token = getToken();
    try {
      const data = await request(`${apis.happiness.getAll}/${id}/share`, {
        method: "POST",
        headers: { ...jsonHeader, Authorization: token },
      });
      return resolve(data);
    } catch (e) {
      return reject(e);
    }
  });

export const getDyanimicHappinessOptions = () =>
  new Promise(async (resolve, reject) => {
    const token = getToken();
    try {
      const data = await request(
        `${apis.adminConfig.getDyanimicHappinessOptions}`,
        {
          method: "GET",
          headers: { ...jsonHeader, Authorization: token },
        }
      );
      return resolve(data);
    } catch (e) {
      return reject(e);
    }
  });

export const getHappinessSettings = () =>
  new Promise(async (resolve, reject) => {
    const token = getToken();
    try {
      const data = await request(`${apis.adminConfig.happinessSetting}`, {
        method: "GET",
        headers: { ...jsonHeader, Authorization: token },
      });
      return resolve(data);
    } catch (e) {
      return reject(e);
    }
  });

export const getNotificateionSettings = () =>
  new Promise(async (resolve, reject) => {
    const token = getToken();
    try {
      const data = await request(`${apis.adminConfig.notificationSetting}`, {
        method: "GET",
        headers: { ...jsonHeader, Authorization: token },
      });
      return resolve(data);
    } catch (e) {
      return reject(e);
    }
  });

export const saveHappinessSettings = (payload) =>
  new Promise(async (resolve, reject) => {
    const token = getToken();
    try {
      const data = await request(`${apis.adminConfig.happinessSetting}`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { ...jsonHeader, Authorization: token },
      });
      NotificationManager.success(getParsedMsg(data));
      return resolve(data);
    } catch (e) {
      NotificationManager.error(getParsedMsg(e));
      return reject(e);
    }
  });

export const saveNotificateionSettings = (payload) =>
  new Promise(async (resolve, reject) => {
    const token = getToken();
    try {
      const data = await request(`${apis.adminConfig.notificationSetting}`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { ...jsonHeader, Authorization: token },
      });
      NotificationManager.success(getParsedMsg(data));
      return resolve(data);
    } catch (e) {
      NotificationManager.error(getParsedMsg(e));
      return reject(e);
    }
  });
