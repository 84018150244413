import React from 'react';
import spacetime from 'spacetime';
import { formatNumber } from 'libphonenumber-js';
import { ReactComponent as UserIcon } from '../../../../../assets/images/user_icon.svg';
import classes from './UserDetails.module.scss';

const UserDetails = ({
  name,
  created_at,
  email,
  mobile,
  gender,
  tasks,
  task_completes,
  dob,
  _id,
  is_active,
  contacts,
}) => (
  <>
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.infobox}>
          <div>
            <UserIcon />
          </div>
          <div>
            <div className={classes.name}>{name}</div>
            <span className={classes.date}>
              {spacetime(new Date(created_at)).format('nice-day')}
            </span>
          </div>
        </div>
        <div className={classes.btnContainer}>
          <div className={classes.reqbtn}>
            <button
              className={is_active ? classes.enable : classes.disabled}
              type="button"
              disabled={is_active}
            >
              Enable
            </button>
            <button
              className={is_active ? classes.disabled : classes.disable}
              type="button"
            >
              Disable
            </button>
          </div>
        </div>
      </div>
      <div className={classes.detailContainer}>
        <div>
          <span>Email Address</span>
          <div className={classes.info}>{email || 'x@abc.com'}</div>
        </div>
        <div>
          <span>Mobile</span>
          <div className={classes.info}>
            {formatNumber(mobile, 'INTERNATIONAL')}
          </div>
        </div>
        <div>
          <span>Gender</span>
          <div className={classes.info}>{gender}</div>
        </div>
        <div>
          <span>DOB</span>
          <div className={classes.info}>
            {spacetime(dob).format('numeric-us')}
          </div>
        </div>
        <div>
          <span># HF</span>
          <div className={classes.info}>{_id}</div>
        </div>
        <div>
          <span># Friends</span>
          <div className={classes.info}>{contacts.length}</div>
        </div>
        <div>
          <span>Status</span>
          <div className={classes.info}>Active</div>
        </div>
      </div>
      <div className={classes.detailContainer}>
        <div className={classes.happiness}>
          <span>Happiness</span>
          <div>{tasks.length}</div>
        </div>
        <div className={classes.happiness}>
          <span>Contacts</span>
          <div>{contacts.length}</div>
        </div>
      </div>
    </div>
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.infobox}>
          <div>
            <div className={classes.dynamic}>Dynamic Happiness</div>
          </div>
        </div>
      </div>
      <div className={classes.detailContainer}>
        <div>
          <span>Set number of happiness</span>
          <div className={classes.info}>{task_completes.length}</div>
        </div>
        <div>
          <span>Set duration of happiness every</span>
          <div className={classes.info}>1 Day</div>
        </div>
      </div>
    </div>
    <div className={classes.notification}>
      <div className={classes.container}>
        <div className={classes.infobox}>
          <div>
            <div className={classes.dynamic}>Alert & Notification</div>
          </div>
        </div>
      </div>
      <div className={classes.detailContainer}>
        <div>
          <span>Set dynamic happiness reminder</span>
          <div className={classes.info}>10:30 PM</div>
        </div>
      </div>
    </div>
  </>
);

export default UserDetails;
