import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import findLast from 'lodash/findLast';
import Dropzone from 'react-dropzone';
import DatePicker from 'react-datepicker';
import AvatarEditor from 'react-avatar-editor';
import Button from '../../Button';
import 'react-datepicker/dist/react-datepicker.css';
import Input from '../../Input';
import { compressHappinessImage } from '../../../utils';
import { getRecommendUsers } from '../../../utils/api_calls';
import Loader from '../../Loader';
import HappinessImage from '../../HappinessImage';
import constants from '../../../config/constants';
import classes from './CreateEditOld.module.scss';
import defaultImage from '../../../assets/images/help-banner.png';

class CreateEditHappiness extends Component {
  constructor(props) {
    super(props);

    this.isEdit = props.isEdit;
    this.state = {
      users: [],
      _id: props._id ? props._id : null,
      title: props.title ? props.title : '',
      execute_date: props.execute_date ? props.execute_date : null,
      description: props.description ? props.description : '',
      help_to: props.help_to ? props.help_to : '',
      recommend_to: props.recommend_to ? props.recommend_to : '',
      address: props.address ? props.address : '',
      image: props.image ? props.image : null,
      loading: true,
      submitLoading: false,
      errors: {},
      scale: 3,
    };
  }

  async componentDidMount() {
    const users = await getRecommendUsers();
    this.setState({ users, loading: false });
  }

  validate = () => {
    const errors = {};
    const { title } = this.state;
    let isError = false;
    if (title.trim().length <= 0) {
      errors.title = 'Full title is required';
      isError = true;
    }

    this.setState({ errors });
    return isError;
  };

  handleDrop = (dropped) => {
    this.setState({ image: dropped[0] });
  };

  clearInput = () => {
    this.setState({
      title: '',
      execute_date: null,
      description: '',
      help_to: '',
      recommend_to: '',
      address: '',
      image: null,
    });
  };

  submit = async (e) => {
    e.preventDefault();
    if (this.validate()) {
      return Promise.resolve();
    }
    const data = { ...this.state };
    this.setState({ submitLoading: true });
    if (
      this.imageRef &&
      this.imageRef.props.image &&
      typeof this.imageRef.props.image !== 'string'
    ) {
      const img = await fetch(this.imageRef.getImage().toDataURL());
      let filename = this.state.image.name;
      if (filename.includes('.png')) {
        filename = filename.replace(/.png/g, '.jpeg');
      }
      const blob = await img.blob();
      data.image = await compressHappinessImage(blob, filename);
    } else {
      data.image = null;
    }

    if (data.recommend_to) {
      const recommend_to = findLast(
        this.state.users,
        (user) => user.value.mobile === data.recommend_to
      );
      data.recommend_to = recommend_to.value;
    }

    if (data.help_to) {
      const help_to = findLast(
        this.state.users,
        (user) => user.value.mobile === data.help_to
      );
      data.help_to = help_to.value;
    }

    try {
      await this.props.submitHappiness(data, this.state._id);
      if (!this.isEdit) {
        this.clearInput();
      }
      this.setState({ submitLoading: false });
      this.props.history.push(`/${constants.adminPath}/happinesses`);
    } catch (e) {
      this.setState({ submitLoading: false });
    }
  };

  cancelClickHandler = () => {
    this.props.history.push(`/${constants.adminPath}/happinesses`);
  };

  setImageRef = (imageRef) => (this.imageRef = imageRef);

  removeImage = () => this.setState({ image: null });

  setScale = (e) => this.setState({ scale: e.target.value });

  handleInputChange = (key, value) => {
    const errors = { ...this.state.errors };
    errors[key] = '';
    this.setState({
      [key]: value,
      errors,
    });
  };

  render() {
    const {
      loading,
      submitLoading,
      title,
      execute_date,
      users,
      description,
      help_to,
      recommend_to,
      address,
      image,
      errors,
    } = this.state;
    if (loading) {
      return <Loader />;
    }

    return (
      <div className={classes.adminSmallCont}>
        <div className={classes.addhappinessBlock}>
          <h3 className={classes.pageTitle}>Add Happiness</h3>
          <form onSubmit={this.submit}>
            {!image && (
              <Dropzone
                onDrop={this.handleDrop}
                multiple={false}
                accept="image/*"
                className={classes.happinessImage}
              >
                <HappinessImage
                  width="695"
                  height="150"
                  alt={title}
                  src={defaultImage}
                />
                <button type="button" className={classes.browseButton}>
                  Browse from computer
                </button>
              </Dropzone>
            )}
            {image && typeof image !== 'string' && (
              <AvatarEditor
                ref={this.setImageRef}
                width={695}
                height={150}
                border={1}
                scale={0.7 * this.state.scale}
                image={image}
              />
            )}
            {image && typeof image === 'string' && (
              <div className={classes.happinessImage}>
                <HappinessImage
                  width="695"
                  height="150"
                  alt={title}
                  src={image}
                />
              </div>
            )}
            {image && typeof image !== 'string' && (
              <Input
                className={classes.inputText}
                type="range"
                value={this.state.scale}
                min="1.46"
                step="0.01"
                max="10"
                onChange={this.setScale}
              />
            )}
            {image && <Button onClick={this.removeImage}>Remove</Button>}
            <div className={classes.happinessForm}>
              <div className={[classes.formField, classes.cols6].join(' ')}>
                <label className={classes.formLable}>Full title *</label>
                <Input
                  className={classes.inputText}
                  type="text"
                  name="title"
                  value={title}
                  error={errors.title}
                  placeholder="title"
                  onChange={(e) =>
                    this.handleInputChange('title', e.target.value)
                  }
                />
              </div>
              <div className={[classes.formField, classes.cols6].join(' ')}>
                <label className={classes.formLable}>Date, Time</label>
                <DatePicker
                  className={classes.inputText}
                  selected={execute_date}
                  onChange={(date) =>
                    this.handleInputChange('execute_date', date)
                  }
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  withPortal
                  minDate={new Date()}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption="time"
                  placeholderText="Date, Time"
                />
              </div>
              <div className={[classes.formField, classes.cols6].join(' ')}>
                <label className={classes.formLable}>Help to?</label>
                <select
                  value={help_to}
                  onChange={(e) =>
                    this.handleInputChange('help_to', e.target.value)
                  }
                >
                  <option value="">Select</option>
                  {users.map((user) => (
                    <option key={user.value.mobile} value={user.value.mobile}>
                      {user.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className={[classes.formField, classes.cols6].join(' ')}>
                <label className={classes.formLable}>Recommend to? *</label>
                <select
                  disabled={this.isEdit}
                  value={recommend_to}
                  onChange={(e) =>
                    this.handleInputChange('recommend_to', e.target.value)
                  }
                >
                  <option value="">All</option>
                </select>
              </div>
              <div className={classes.formField}>
                <label className={classes.formLable}>
                  Add complete address
                </label>
                <Input
                  className={classes.inputText}
                  type="text"
                  name="address"
                  value={address}
                  placeholder="address"
                  onChange={(e) =>
                    this.handleInputChange('address', e.target.value)
                  }
                />
              </div>
              <div className={classes.formField}>
                <label className={classes.formLable}>Description</label>
                <textarea
                  className={classes.inputTextArea}
                  value={description}
                  name="description"
                  placeholder="description"
                  onChange={(e) =>
                    this.handleInputChange('description', e.target.value)
                  }
                />
              </div>
              <div className={[classes.btnContainer, classes.cf].join(' ')}>
                <div className={[classes.cols6].join(' ')}>
                  <Button
                    className={[classes.cancelBtn, classes.formButtons].join(
                      ' '
                    )}
                    type="button"
                    onClick={this.cancelClickHandler}
                  >
                    Cancel
                  </Button>
                </div>
                <div className={[classes.right, classes.cols6].join(' ')}>
                  <Button
                    className={[classes.saveBtn, classes.formButtons].join(' ')}
                    loading={submitLoading}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateEditHappiness);
