import React, { Component } from 'react';
import { parseQuery } from '../../utils';

export default class RedirectMobile extends Component {
  componentDidMount() {
    const isiOS =
      navigator.userAgent.match('iPad') ||
      navigator.userAgent.match('iPhone') ||
      navigator.userAgent.match('iPod');
    const isAndroid = navigator.userAgent.match('Android');
    let fallbackLink = 'https://happinessfactory.app/';

    const params = parseQuery(this.props.location.search);
    let mobileUrl = '';
    if (isiOS) {
      mobileUrl = params.ios + mobileUrl;
    }

    if (isAndroid) {
      mobileUrl = params.android + mobileUrl;
    }

    if (mobileUrl) {
      mobileUrl = mobileUrl + params.apppath;
      window.location.replace(mobileUrl);
      fallbackLink = fallbackLink = isAndroid
        ? 'market://details?id=com.mycompany.myapp'
        : 'itms-apps://itunes.apple.com/app/my-app/idxxxxxxxx?mt=8';
    }
    window.setTimeout(function() {
      window.location.replace(fallbackLink);
    }, 2000);
  }

  render() {
    return <div>Redirecting...</div>;
  }
}
