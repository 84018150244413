import React from 'react';
import spacetime from 'spacetime';
import HelpBanner from '../../../../../assets/images/help-banner-default.png';

import classes from './TaskList.module.scss';

const TaskList = ({ tasks, onClick }) => (
	<div className={classes.root}>
		{tasks.map((task) => (
			<div
				style={{ cursor: 'pointer' }}
				key={task._id}
				onClick={() => onClick(task._id)}
			>
				<img
					src={task.photos.length > 0 ? task.photos[0].img : HelpBanner}
					alt="happiness"
				/>
				<div className={classes.textContainer}>
					<div className={classes.title}>{task.title}</div>
					<div className={classes.created}>
						{spacetime(new Date(task.execute_date)).format('nice-short')}
					</div>
				</div>
			</div>
		))}
	</div>
);

export default TaskList;
