import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { doLogout } from '../../../utils/auth';
import constants from '../../../config/constants';

export default class Logout extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    doLogout();
    this.setState({
      loading: false
    });
  }

  render() {
    const { loading } = this.state;

    if (loading)
      return (
        <div>
          <span>logging out...</span>
        </div>
      );

    return <Redirect to={`/${constants.adminPath}/login`} />;
  }
}
