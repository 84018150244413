import React, { Component } from 'react';
import { isEmail } from 'validator';

import { login } from '../../../utils/api_calls';
import constants from '../../../config/constants';
import Button from '../../../components/Button';
import Input from '../../../components/Input';

import classes from './Login.module.scss';

import appIcon from '../../../assets/images/app-icon.png';

export default class Login extends Component {
  state = {
    email: '',
    password: '',
    submitLoading: false,
    errors: {}
  };

  componentDidMount() {
    document.body.classList.add('loginBlock');
  }

  validate = () => {
    const errors = {};
    const { email, password } = this.state;
    let isError = false;

    if (email.trim().length <= 0) {
      errors.email = 'Email Address is required';
      isError = true;
    } else if (!isEmail(email)) {
      errors.email = 'Please enter valid email address';
      isError = true;
    }

    if (password.trim().length <= 0) {
      errors.password = 'Password is required';
      isError = true;
    }
    this.setState({ errors });
    return isError;
  };

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  submit = async e => {
    e.preventDefault();
    if (this.validate()) {
      return Promise.resolve();
    }
    const { email, password } = this.state;

    this.setState({ submitLoading: true });

    try {
      await login({ email, password });
      this.setState({ submitLoading: false });
      this.props.history.push({
        pathname: `/${constants.adminPath}/`
      });
    } catch (e) {
      this.setState({ submitLoading: false });
    }
  };

  render() {
    const { email, password, submitLoading, errors } = this.state;

    return (
      <div className={classes.loginContainer}>
        <div className={classes.loginTop}>
          <h1 className={classes.header1}>
            <img
              width="100"
              height="100"
              alt="happiness factory"
              src={appIcon}
            />
          </h1>
          <h2 className={classes.header2}>Happiness Factory</h2>
          <h3 className={classes.header3}>Admin Login</h3>
        </div>
        <div className={classes.loginInputs}>
          <form onSubmit={this.submit}>
            <div className={classes.formField}>
              <label className={classes.formLable}>Email Address</label>
              <Input
                className={classes.inputText}
                type="email"
                placeholder="email"
                name="email"
                error={errors.email}
                value={email}
                onChange={this.handleInputChange}
              />
            </div>
            <div className={classes.formField}>
              <label className={classes.formLable}>Password</label>
              <Input
                className={classes.inputText}
                type="password"
                placeholder="password"
                name="password"
                error={errors.password}
                value={password}
                onChange={this.handleInputChange}
              />
            </div>
            <div className={classes.loginButtonDiv}>
              <Button
                className={classes.loginBtn}
                loading={submitLoading ? true : false}
                type="submit"
              >
                Login Now
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
