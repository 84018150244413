import Compressor from 'compressorjs';

export const blobToFile = (blob, fileName) => {
	const image = new File([blob], fileName, { type: blob.type });
	return image
}

export const compressHappinessImage = (file, filename) => new Promise((resolve, reject) => {
	new Compressor(file, {
		quality: 0.6,
		width: 280 * 8,
		height: 100 * 8,
		maxWidth: 1024,
		maxHeight: 768,
		mimeType: 'image/jpeg',
		success(result) {
			const image = blobToFile(result, filename)
			return resolve(image)
		},
		error(err) {
			return reject(err)
		},
	});
})

export const parseQuery = (queryString) => {
    const query = {};
    let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}
