import React from 'react';
import HappinessItem from '../UserItem';
import Button from '../../Button';
import classes from './UserList.module.scss';

const UserList = ({
  happinesses,
  navigateToView,
  loadMoreHappiness,
  is_more,
  updateTaskStatus,
  loadingMore,
}) => {
  return (
    <div>
      <div className={classes.tableHead}>
        <div className={[classes.tableHeader, classes.helpDetails].join(' ')}>
          Title
        </div>
        <div className={[classes.tableHeader, classes.requestTime].join(' ')}>
          Date,Time
        </div>
        <div className={[classes.tableHeader, classes.postedBy].join(' ')}>
          Posted by?
        </div>
        <div className={[classes.tableHeader, classes.helpTo].join(' ')}>
          Help to?
        </div>
        <div className={[classes.tableHeader, classes.recommendTo].join(' ')}>
          Recommend to?
        </div>
        <div className={[classes.tableHeader, classes.like].join(' ')}>
          Likes / Dislikes
        </div>
        <div className={[classes.tableHeader, classes.reqStatus].join(' ')}>
          Status
        </div>
        <div className={[classes.tableHeader, classes.requestTime].join(' ')}>
          Request Date,Time
        </div>
        <div className={[classes.tableHeader, classes.action].join(' ')}>
          Action
        </div>
      </div>
      {happinesses &&
        happinesses.map((happiness) => (
          <HappinessItem
            updateTaskStatus={updateTaskStatus}
            happiness={happiness}
            navigateToView={navigateToView}
            key={happiness._id}
          />
        ))}
      {is_more && !loadingMore && (
        <div>
          <Button onClick={loadMoreHappiness}>Load More</Button>
        </div>
      )}
      {loadingMore && (
        <div>
          <span>loading more...</span>
        </div>
      )}
    </div>
  );
};

export default UserList;
