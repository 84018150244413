import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Loader from '../../../components/Loader';
import { getHappinesses, updateTaskVisibility } from '../../../utils/api_calls';
import UserList from '../../../components/Happiness/UserList';
import AdminList from '../../../components/Happiness/AdminList';
import constants from '../../../config/constants';
import classes from './List.module.scss';

let controller = new AbortController();

class ListHappiness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      happinesses: [],
      loading: true,
      is_more: false,
      loadingMore: false,
      page_size: 20,
      selectedRating: '',
      selectedAction: '',
      role: 'admin',
      search: '',
    };
  }

  componentDidMount() {
    this.fetchHappiness(this.prepareQueryObject());
  }

  onUpdateTaskStatus = async (happiness) => {
    const happinessResponse = await updateTaskVisibility(happiness._id);
    const { happinesses } = this.state;
    const updatedTask = happinessResponse.data.task;
    const updatedTaskList = happinesses.map((task) => {
      if (updatedTask._id === task._id) {
        task = updatedTask;
      }
      return task;
    });
    this.setState({ happinesses: updatedTaskList });
  };

  fetchHappiness = async (obj) => {
    controller.abort();
    controller = new AbortController();
    const signal = controller.signal;

    this.setState({
      loading: true,
    });
    const parms = { ...obj };
    if (parms.role === 'admin') {
      delete parms.rating;
    }
    try {
      const happinesses = await getHappinesses(1, parms,signal);
      this.setState({
        happinesses: happinesses.data,
        page_size: happinesses.page_size,
        loading: false,
        is_more: happinesses.is_more,
        seaarchList: happinesses.data,
      });
    } catch (error) {
      this.setState({
        happinesses: [],
        loading: false,
      });
    }
  };

  loadMoreHappiness = async () => {
    this.setState({ loadingMore: true });
    const { happinesses, page_size } = this.state;
    const page = Math.round(happinesses.length / page_size);
    const new_happinesses = await getHappinesses(
      page + 1,
      this.prepareQueryObject()
    );
    const all_happinesses = [...happinesses, ...new_happinesses.data];
    this.setState({
      loadingMore: false,
      happinesses: all_happinesses,
      page_size: new_happinesses.page_size,
      loading: false,
      is_more: new_happinesses.is_more,
    });
  };

  navigateToUserView = (id) => {
    this.props.history.push(
      `/${constants.adminPath}/happinesses/${id}/userview`
    );
  };
  navigateToAdminView = (id) => {
    this.props.history.push(
      `/${constants.adminPath}/happinesses/${id}/adminview`
    );
  };
  navigateToUserView = (id) => {
    this.props.history.push(
      `/${constants.adminPath}/happinesses/${id}/userview`
    );
  };

  prepareQueryObject = ({ role, rating, status, search } = {}) => {
    return {
      role: role || this.state.role,
      status: status === undefined ? this.state.selectedAction : status,
      rating: rating === undefined ? this.state.selectedRating : rating,
      search: search === undefined ? this.state.search : search,
    };
  };

  handleRatingDropdown = (e) => {
    const ratingValue = e.target.value;
    const obj = this.prepareQueryObject({ rating: ratingValue });
    this.setState({ selectedRating: ratingValue });
    this.fetchHappiness(obj);
  };

  handleActionDropdown = (e) => {
    const value = e.target.value;
    const obj = this.prepareQueryObject({ status: value });
    this.setState({ selectedAction: value });
    this.fetchHappiness(obj);
  };

  handleDebounce = (searchValue, debounceTime) => {
    const { timer } = this.state;
    clearTimeout(timer);

    this.setState({
      timer: setTimeout(() => {
        this.fetchHappiness(this.prepareQueryObject({ search: searchValue }));
      }, debounceTime),
    });
  };

  handleOnChangeSearch = (e) => {
    const strKeyword = e.target.value;

    this.setState({ search: strKeyword });
    this.handleDebounce(strKeyword, 250);
  };

  render() {
    const {
      happinesses,
      loading,
      is_more,
      loadingMore,
      role,
      search,
      selectedAction,
      selectedRating,
    } = this.state;

    const handleTab = (role) => {
      const obj = this.prepareQueryObject({ role });
      this.setState({ role, happinesses: [], selectedRating: '' });
      this.fetchHappiness(obj);
    };

    return (
      <div className={classes.adminContent}>
        <div className={classes.cf}>
          <div className={[classes.filRow, classes.search].join(' ')}>
            <label className={classes.searchLabel}>Search by title</label>
            <input
              className={[classes.searchInput, classes.inputText].join(' ')}
              type="text"
              name="filterSearch"
              value={search}
              onChange={this.handleOnChangeSearch}
            />
          </div>
          {role === 'user' && (
            <div className={classes.filRow}>
              <label htmlFor="rating" className={classes.searchLabel}>
                Rating
              </label>
              <select
                value={selectedRating}
                className={classes.select}
                id="rating"
                onChange={this.handleRatingDropdown}
              >
                <option value="">All</option>
                <option value="1">Liked</option>
                <option value="0">Disliked</option>
              </select>
            </div>
          )}
          <div className={classes.filRow}>
            <label htmlFor="action" className={classes.searchLabel}>
              Action
            </label>
            <select
              value={selectedAction}
              className={classes.select}
              id="action"
              onChange={this.handleActionDropdown}
            >
              <option value="">All</option>
              <option value="1">Enable</option>
              <option value="0">Disable</option>
            </select>
          </div>
        </div>
        <div className={classes.tabContainer}>
          <Tabs>
            <TabList className={classes.tabList}>
              <Tab
                className={[
                  classes.tabs,
                  role === 'admin' ? classes.activeTab : '',
                ].join(' ')}
                onClick={() => handleTab('admin')}
              >
                Admin
              </Tab>
              <Tab
                className={[
                  classes.tabs,
                  role === 'user' ? classes.activeTab : ' ',
                ].join(' ')}
                onClick={() => handleTab('user')}
              >
                User
              </Tab>
            </TabList>
            <TabPanel>
              <div className={classes.requestsBlock}>
                {loading ? (
                  <Loader />
                ) : (
                  <AdminList
                    happinesses={happinesses}
                    updateTaskStatus={this.onUpdateTaskStatus}
                    is_more={is_more}
                    navigateToView={this.navigateToAdminView}
                    loadMoreHappiness={this.loadMoreHappiness}
                    loadingMore={loadingMore}
                  />
                )}
              </div>
            </TabPanel>
            <TabPanel>
              <div className={classes.requestsBlock}>
                {loading ? (
                  <Loader />
                ) : (
                  <UserList
                    happinesses={happinesses}
                    updateTaskStatus={this.onUpdateTaskStatus}
                    is_more={is_more}
                    navigateToView={this.navigateToUserView}
                    loadMoreHappiness={this.loadMoreHappiness}
                    loadingMore={loadingMore}
                  />
                )}
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default ListHappiness;
