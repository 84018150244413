import React from 'react';

const Button = props => {
  return (
    <button {...props}>
      {props.loading ? (
        <i className="fa fa-spinner fa-spin"></i>
      ) : (
        props.children
      )}
    </button>
  );
};

export default Button;
