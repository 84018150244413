import React from 'react';
import AdminItem from '../AdminItem/index';
import Button from '../../Button';
import classes from './AdminList.module.scss';

const AdminList = ({
  happinesses,
  navigateToView,
  loadMoreHappiness,
  is_more,
  updateTaskStatus,
  loadingMore,
}) => {
  return (
    <div>
      <div className={classes.tableHead}>
        <div className={[classes.tableHeader, classes.helpDetails].join(' ')}>
          Title
        </div>
        <div className={[classes.tableHeader, classes.like].join(' ')}>
          Likes / Dislikes
        </div>
        <div className={[classes.tableHeader, classes.requestTime].join(' ')}>
          Date,Time
        </div>
        <div className={[classes.tableHeader, classes.reqStatus].join(' ')}>
          Action
        </div>
      </div>
      {happinesses &&
        happinesses.map((happiness) => (
          <AdminItem
            updateTaskStatus={updateTaskStatus}
            happiness={happiness}
            navigateToView={navigateToView}
            key={happiness._id}
          />
        ))}
      {is_more && !loadingMore && (
        <div>
          <Button className={classes.btn} onClick={loadMoreHappiness}>
            Load More
          </Button>
        </div>
      )}
      {loadingMore && (
        <div>
          <span>loading more...</span>
        </div>
      )}
    </div>
  );
};

export default AdminList;
