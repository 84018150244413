import React, { Component } from 'react';
import classes from './UnderConstruction.module.scss';
import appIcon from '../../assets/images/app-icon.png';

export default class UnderConstruction extends Component {
  componentDidMount() {
    document.body.classList.add('loginBlock');
  }

  render() {
    return (
      <div className={[classes.loginContainer, classes.ucCont].join(' ')}>
        <div className={[classes.loginTop, classes.ucBlock].join(' ')}>
          <h1 className={classes.head1}>
            <img
              width="100"
              height="100"
              alt="Happiness Factory"
              src={appIcon}
            />
          </h1>
          <h2 className={classes.head2}>Happiness Factory</h2>
          <h3 className={classes.head3}>
            Happiness is not something ready made,
            <br />
            It comes from your own actions!
          </h3>
          <p className={classes.footerTitle}>
            Help <span className={classes.spanp}>::</span> Care{' '}
            <span className={classes.spanp}>::</span> Love
          </p>
        </div>
        <div className={classes.ucText}>
          <h4 className={classes.head4}>Under Construction</h4>
          <h5 className={classes.head5}>An all-new site is coming soon.</h5>
        </div>
      </div>
    );
  }
}
