import React, { useEffect, useState } from 'react';
import classes from './Settings.module.scss';
import {
  getDyanimicHappinessOptions,
  getNotificateionSettings,
  getHappinessSettings,
  saveHappinessSettings,
  saveNotificateionSettings,
} from '../../utils/api_calls';
import Loader from '../../components/Loader';
import moment from 'moment';

const Settings = ({ props }) => {
  const [stateObject, setStateObject] = useState({
    cards: [],
    selectedCard: undefined,
    selectedDuration: undefined,
    happinessRemider: undefined,
    days: [],
  });
  const [loading, setLoading] = useState(true);

  const handleSubmitDynamicHappiness = async (event) => {
    const { selectedCard, selectedDuration } = stateObject;
    event.preventDefault();
    const params = {
      number_of_cards: selectedCard,
      happiness_duration: selectedDuration,
    };
    try {
      setLoading(true);
      await saveHappinessSettings(params);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmitAlertAndNotification = async (event) => {
    const { happinessRemider } = stateObject;
    event.preventDefault();
    const formatedTime = moment(happinessRemider, ['HH:mm']).format('hh:mm A');
    const params = {
      hour: formatedTime.split(' ')[0].split(':')[0],
      minute: formatedTime.split(' ')[0].split(':')[1],
      am_pm: formatedTime.split(' ')[1],
    };
    try {
      setStateObject({ ...stateObject, loading: true });
      await saveNotificateionSettings(params);
      setStateObject({ ...stateObject, loading: false });
    } catch (error) {
      setStateObject({ ...stateObject, loading: false });
    }
  };

  const handleCards = (e) => {
    setStateObject({ ...stateObject, selectedCard: e.target.value });
  };

  const handleDays = (e) => {
    setStateObject({ ...stateObject, selectedDuration: e.target.value });
  };

  const handleTime = (e) => {
    const selectedTime = (e.target.value || '').toLowerCase();
    if (
      selectedTime &&
      (selectedTime.indexOf('pm') > -1 || selectedTime.indexOf('am') - 1)
    ) {
      setStateObject({ ...stateObject, happinessRemider: e.target.value });
    } else {
      setStateObject({ ...stateObject, happinessRemider: selectedTime });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dyanimicHappinessOptions = await getDyanimicHappinessOptions();
        const happinessSettings = await getHappinessSettings();
        const notificationSettings = await getNotificateionSettings();
        const {
          cards_option = [],
          duration_option = [],
        } = dyanimicHappinessOptions;
        const cardOptions = cards_option.map((elem) => {
          const mappedElement = { value: elem, displayValue: '' };
          mappedElement.displayValue = `${elem} Card${elem > 1 ? 's' : ''}`;
          return mappedElement;
        });

        const { hour, minute, am_pm } = notificationSettings.happiness_reminder;
        const happinessRemider = moment(`${hour}:${minute} ${am_pm}`, [
          'hh:mm a',
        ]).format('HH:mm');
        setStateObject({
          cards: cardOptions,
          days: duration_option,
          selectedCard: happinessSettings.number_of_cards,
          selectedDuration: happinessSettings.happiness_duration,
          happinessRemider,
        });
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const {
    selectedCard,
    selectedDuration,
    happinessRemider,
    cards,
    days,
  } = stateObject;
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div className={classes.root}>
        <h3 className={classes.heading}>Dynamic Happiness</h3>
        <form onSubmit={handleSubmitDynamicHappiness}>
          <div>
            <label className={classes.label}>
              Number of actions you would like to send each user
            </label>
          </div>
          <div>
            <select
              className={classes.select}
              name="number_of_cards"
              onChange={handleCards}
              value={selectedCard}
              placeholder="Select number of cards"
            >
              {cards.map((option) => (
                <option
                  className={classes.option}
                  key={option.value}
                  value={option.value}
                >
                  {option.displayValue}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className={classes.label}>
              Set duration of happiness every
            </label>
          </div>
          <div>
            <select
              name="happiness_duration"
              placeholder="Select duration"
              className={classes.select}
              value={selectedDuration}
              onChange={handleDays}
            >
              {days.map((option) => (
                <option className={classes.option} key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div>
            <button type="submit" className={classes.Btn}>
              Save
            </button>
          </div>
        </form>
        <div className={classes.line}></div>
        <h3 className={classes.heading}>Alert & Notification</h3>
        <form onSubmit={handleSubmitAlertAndNotification}>
          <div>
            <label className={classes.label}>
              Set dynamic happiness reminder
            </label>
          </div>
          <div>
            <input
              type="time"
              className={classes.time}
              value={happinessRemider}
              onChange={handleTime}
            />
          </div>
          <div>
            <button type="submit" className={classes.Btn}>
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Settings;
