import React from 'react';
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import Footer from '../Footer';
import Container from '../Container';
import classes from './Layout.module.scss';

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <Container>
        <LeftSidebar />
        <section className={classes.adminContent}>{children}</section>
      </Container>
      <Footer />
    </div>
  );
};

export default Layout;
