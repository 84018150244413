import React from 'react';
import UserItem from '../UserItem';
import Button from '../Button';
import classes from './UserList.module.scss';

const UserList = ({
  users,
  navigateToView,
  loadMoreUsers,
  is_more,
  updateUserStatus,
  loadingMore,
}) => {
  return (
    <div>
      <div className={[classes.userHead, classes.cf].join(' ')}>
        <div className={[classes.tabRow, classes.usersName].join(' ')}>
          User Name
        </div>
        <div className={[classes.tabRow, classes.usersEmail].join(' ')}>
          Email Address
        </div>
        <div className={[classes.tabRow, classes.usersMobile].join(' ')}>
          Mobile
        </div>
        <div className={[classes.tabRow, classes.usersGender].join(' ')}>
          Gender
        </div>
        <div className={[classes.tabRow, classes.usersBdate].join(' ')}>
          DOB
        </div>
        <div className={[classes.tabRow, classes.usersHf].join(' ')}># HF</div>
        <div className={[classes.tabRow, classes.usersFriend].join(' ')}>
          # Friends
        </div>
        <div className={[classes.tabRow, classes.usersStatus].join(' ')}>
          Status
        </div>
      </div>
      {users.map((user) => (
        <UserItem
          user={user}
          navigateToView={navigateToView}
          updateUserStatus={updateUserStatus}
          key={user._id}
        />
      ))}
      {is_more && !loadingMore && (
        <Button className={classes.load} onClick={loadMoreUsers}>
          Load More
        </Button>
      )}
      {loadingMore && (
        <div>
          <span>loading more...</span>
        </div>
      )}
    </div>
  );
};

export default UserList;
