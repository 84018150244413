/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { formatNumber } from 'libphonenumber-js';
import { ReactComponent as UserIcon } from '../../../../../assets/images/user_icon.svg';
import classes from './ContactList.module.scss';

const ContactList = ({ contacts }) => (
	<div>
		<div className={classes.sources}>
			<div>
				<div className={classes.active}>
					<i className="far fa-address-book fa-lg"></i>
				</div>
				<div>
					<span>Addressbook</span>
					<br />
					<span>Sync - On</span>
				</div>
			</div>
			<div className={classes.fb}>
				<div>
					<i className="far fa-facebook-square fa-lg"></i>
				</div>
				<div>
					<span>Facebook</span>
					<br />
					<span>Sync - Off</span>
				</div>
			</div>
			<div className={classes.linkedin}>
				<div>
					<i className="far fa-linkedin-square fa-lg"></i>
				</div>
				<div>
					<span>LinkedIn</span>
					<br />
					<span>Sync - Off</span>
				</div>
			</div>
			<div className={classes.google}>
				<div>
					<i className="fab fa-google-plus fa-lg"></i>
				</div>
				<div>
					<span>Google</span>
					<br />
					<span>Sync - Off</span>
				</div>
			</div>
		</div>
		<div className={[classes.userHead, classes.cf].join(' ')}>
			<div className={[classes.tabRow, classes.usersName].join(' ')}>
				User Name
			</div>
			<div className={[classes.tabRow, classes.usersEmail].join(' ')}>
				Email Address
			</div>
			<div className={[classes.tabRow, classes.usersMobile].join(' ')}>
				Mobile
			</div>
			<div className={[classes.tabRow, classes.usersPlatform].join(' ')}>
				Sync Platform
			</div>
			<div className={[classes.tabRow, classes.usersStatus].join(' ')}>
				Status
			</div>
		</div>
		{contacts.map((contact) => (
			<div
				key={contact.mobile}
				className={[classes.userContent, classes.cf].join(' ')}
			>
				<div className={[classes.tabRow, classes.usersName].join(' ')}>
					<UserIcon />
					{contact.name}
				</div>
				<div className={[classes.tabRow, classes.usersEmail].join(' ')}>
					{contact.email || 'NA'}
				</div>
				<div className={[classes.tabRow, classes.usersMobile].join(' ')}>
					{formatNumber(contact.mobile, 'INTERNATIONAL')}
				</div>
				<div className={[classes.tabRow, classes.usersPlatform].join(' ')}>
					Addressbook
				</div>
				<div className={[classes.tabRow, classes.usersStatus].join(' ')}>
					{!contact.is_invited ? <a href="#">Invite</a> : ''}
				</div>
			</div>
		))}
	</div>
);

export default ContactList;
