import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getUser } from '../../../utils/api_calls';
import UserDetails from './components/UserDetails';
import TaskList from './components/TasksList';
import ContactList from './components/ContactList';
import Loader from '../../../components/Loader';
import constants from '../../../config/constants';
import classes from './View.module.scss';

const HAPPINESS = 'happness';
// const CONTACTS = 'contacts';
export default class ViewUser extends Component {
  state = {
    loading: true,
    user: {},
    activeTab: HAPPINESS,
  };

  async componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (!id) this.goBack();

    try {
      const user = await getUser(id);
      this.setState({ user, loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  navigateToTaskDetail = (id) => {
    this.props.history.push(`/${constants.adminPath}/happinesses/${id}/view`);
  };

  render() {
    const { loading, user, activeTab } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <div className={classes.root}>
        <Link to={`/${constants.adminPath}/users`}>Back to all users</Link>
        <UserDetails {...user} />

        <div className={classes.tabContent}>
          {activeTab === HAPPINESS ? (
            <TaskList tasks={user.tasks} onClick={this.navigateToTaskDetail} />
          ) : (
            <ContactList contacts={user.contacts} />
          )}
        </div>
      </div>
    );
  }
}
