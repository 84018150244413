import React, { useState } from "react";
import TopHappinessItem from "../TopHappinessItem";
import classes from "./TopHappinessList.module.scss";
import UpAero from "../../../assets/images/up.svg";
import DownAero from "../../../assets/images/down.svg";

const TopHappinessList = ({ happinesses, navigateToView }) => {
  const [sort, setSort] = useState({
    field: "",
    order: "",
  });

  const handleSort = (field) => {
    let order = "asc";
    if (sort.field === field && sort.order === "asc") {
      order = "desc";
    }
    setSort({ field, order });
  };
  console.log(sort);

  const sortIconView = (field) => {
    let view;
    if (sort.order === "asc" && sort.field === field) {
      view = <img src={DownAero} className={classes.downAero} alt="down" />;
    } else {
      if (sort.order === "desc" && sort.field === field) {
        view = <img src={UpAero} className={classes.upAero} alt="up" />;
      } else {
        view = (
          <div className={classes.sortingDiv}>
            <img src={UpAero} className={classes.upAero} alt="up" />
            <img src={DownAero} className={classes.downAero} alt="down" />
          </div>
        );
      }
    }
    return (
      <div className={classes.sortIcon}>
        {view}
      </div>
    );
  };

  return (
    <div>
      <div className={classes.tableHead}>
        <div
          className={[classes.tableHeader, classes.num].join(" ")}
          onClick={() => handleSort("#")}
        >
          #{sortIconView("#")}
        </div>
        <div className={[classes.tableHeader, classes.helpDetails].join(" ")}>
          Title
        </div>
        <div className={[classes.tableHeader, classes.requestTime].join(" ")}>
          Date,Time
        </div>
        <div
          className={[classes.tableHeader, classes.hf].join(" ")}
          onClick={() => handleSort("Delivered HF")}
        >
          Delivered HF{sortIconView("Delivered HF")}
        </div>
        <div
          className={[classes.tableHeader, classes.hf].join(" ")}
          onClick={() => handleSort("Completed HF")}
        >
          Completed HF{sortIconView("Completed HF")}
        </div>
        <div
          className={[classes.tableHeader, classes.like].join(" ")}
          onClick={() => handleSort("Likes")}
        >
          Likes{sortIconView("Likes")}
        </div>
      </div>
      {happinesses &&
        happinesses.map((happiness, i) => (
          <TopHappinessItem
            index={i}
            happiness={happiness}
            navigateToView={navigateToView}
            key={happiness._id}
          />
        ))}
    </div>
  );
};

export default TopHappinessList;
