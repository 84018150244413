import React from 'react';
import ErrorMessage from '../ErrorMessage';

const Input = props => {
  return (
    <>
      <input
        {...props}
        className={`${props.className ? props.className : ''} ${
          props.error ? 'error' : ''
        }`}
        autoComplete="off"
      />
      {!!props.error && !props.dontShowMessage && (
        <ErrorMessage message={props.error} />
      )}
    </>
  );
};

export default Input;
