import React from 'react';
import { NotificationContainer } from 'react-notifications';
import 'react-circular-progressbar/dist/styles.css';
import RouterComponent from './Router';

const App = () => {
  return (
    <>
      <RouterComponent />
      <NotificationContainer />
    </>
  );
};

export default App;
