import React, { Component } from "react";
import Loader from "../../../components/Loader";
import { getUsers, updateUserStatus } from "../../../utils/api_calls";
import constants from "../../../config/constants";
import classes from "./List.module.scss";
import UserList from "../../../components/UserList";

let controller = new AbortController();

class ListUsers extends Component {
  state = {
    users: [],
    loading: true,
    is_more: false,
    loadingMore: false,
    page_size: 20,
    selectedAction: "",
    search: "",
  };

  componentDidMount() {
    this.fetchUsers(this.prepareQueryObject());
  }

  fetchUsers = async (obj) => {
    controller.abort();
    controller = new AbortController();
    const signal = controller.signal;
    this.setState({
      loading: true,
    });
    const parms = { ...obj };
    try {
      const userResponse = await getUsers(1, parms, signal);
      this.setState({
        users: userResponse.data || [],
        page_size:
          userResponse.page_size === undefined
            ? this.state.page_size
            : userResponse.page_size,
        loading: false,
        is_more:
          userResponse.is_more === undefined
            ? this.state.is_more
            : userResponse.is_more,
      });
    } catch (error) {
      this.setState({
        users: [],
        loading: false,
      });
    }
  };

  loadMoreUsers = async () => {
    this.setState({ loadingMore: true });
    const { users, page_size } = this.state;
    const page = Math.round(users.length / page_size);
    const new_users = await getUsers(page + 1);
    const all_users = [...users, ...new_users.data];
    this.setState({
      loadingMore: false,
      users: all_users,
      page_size: new_users.page_size,
      loading: false,
      is_more: new_users.is_more,
    });
  };

  onUpdateUserStatus = async (user) => {
    const userResponse = await updateUserStatus({ userId: user._id });
    const { users } = this.state;
    const updatedUser = userResponse.data.user;
    if (updatedUser) {
      const updatedUserList = users.map((user) => {
        if (updatedUser._id === user._id) {
          user = updatedUser;
        }
        return user;
      });
      this.setState({ users: updatedUserList });
    }
  };

  navigateToView = (id) => {
    this.props.history.push(`/${constants.adminPath}/users/${id}/view`);
  };

  prepareQueryObject = ({ action, search } = {}) => {
    return {
      status: action === undefined ? this.state.selectedAction : action,
      search: search === undefined ? this.state.search : search,
    };
  };

  handleActionDropdown = (e) => {
    const value = e.target.value;
    const obj = this.prepareQueryObject({ action: value });
    this.fetchUsers(obj);
    this.setState({ selectedAction: value });
  };
  handleDebounce = (searchValue, debounceTime) => {
    const { timer } = this.state;
    clearTimeout(timer);

    this.setState({
      timer: setTimeout(() => {
        this.fetchUsers(this.prepareQueryObject({ search: searchValue }));
      }, debounceTime),
    });
  };

  handleOnChangeSearch = (e) => {
    const strKeyword = e.target.value;
    this.setState({ search: strKeyword });
    this.handleDebounce(strKeyword, 250);
  };

  render() {
    const {
      users,
      loading,
      is_more,
      loadingMore,
      selectedAction,
      search,
    } = this.state;

    return (
      <div>
        <div className={classes.cf}>
          <div className={[classes.filRow, classes.search].join(" ")}>
            <label className={classes.searchLabel}>
              Search by name, email, mobile
            </label>
            <input
              className={[classes.searchInput, classes.inputText].join(" ")}
              type="text"
              name="filterSearch"
              value={search}
              onChange={this.handleOnChangeSearch}
            />
          </div>
          <div className={classes.filRow}>
            <label htmlFor="action" className={classes.searchLabel}>
              Action
            </label>
            <select
              value={selectedAction}
              className={classes.select}
              id="action"
              onChange={this.handleActionDropdown}
            >
              <option value="">All</option>
              <option value="1">Enable</option>
              <option value="0">Disable</option>
            </select>
          </div>
        </div>
        <div className={classes.usersBlock}>
          {loading ? (
            <Loader />
          ) : (
            <UserList
              users={users}
              is_more={is_more}
              navigateToView={this.navigateToView}
              loadMoreUsers={this.loadMoreUsers}
              loadingMore={loadingMore}
              updateUserStatus={this.onUpdateUserStatus}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ListUsers;
