import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import constants from '../../config/constants';
import classes from './LeftSidebar.module.scss';
import Happiness from '../../assets/images/all-happiness.svg';
import SettingIcon from '../../assets/images/Combined Shape.svg';
import UserIcon from '../../assets/images/hf-users.svg';
 
const LeftSidebar = ({ children, location }) => {
  return (
    <aside className={classes.sidebar}>
      <ul className={[classes.noList, classes.sbTopLv].join(' ')}>
        <li
          className={[
            location.pathname === `/${constants.adminPath}/`
              ? classes.active
              : '',
            classes.menuItem
          ].join(' ')}
        >
          <Link
            to={`/${constants.adminPath}/`}
            className={classes.menuItemLink}
          >
            <i className={[classes.menuIcon, 'fa fa-dashboard'].join(' ')}></i>
            <span className={classes.menuItemSpan}>Dashboard</span>
          </Link>
        </li>

        <li
          className={[
            location.pathname === `/${constants.adminPath}/happinesses`
              ? classes.active
              : '',
            classes.menuItem
          ].join(' ')}
        >
          <Link
            to={`/${constants.adminPath}/happinesses`}
            className={classes.menuItemLink}
          >
            <img src={Happiness} alt="happiness"
              className={[classes.menuIcon, 'fas fa-envelope-open'].join(' ')}
            />
            <span className={classes.menuItemSpan}>All Happiness</span>
          </Link>
        </li>
        <li
          className={[
            location.pathname === `/${constants.adminPath}/users`
              ? classes.active
              : '',
            classes.menuItem
          ].join(' ')}
        >
          <Link
            to={`/${constants.adminPath}/users`}
            className={classes.menuItemLink}
          >
            <img src={UserIcon} alt="user" />
            <span className={classes.menuItemSpan}>All Users</span>
          </Link>
        </li>
        <li
          className={[
            location.pathname === `/${constants.adminPath}/settings`
              ? classes.active
              : '',
            classes.menuItem
          ].join(' ')}
        >
          <Link
            to={`/${constants.adminPath}/settings`}
            className={classes.menuItemLink}
          >
            <img src={SettingIcon} alt="setting"
            />
            <span className={classes.menuItemSpan}>Settings</span>
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default withRouter(LeftSidebar);
