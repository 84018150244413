import React, { Component } from 'react';
import { getHappiness } from '../../../utils/api_calls';
import spacetime from 'spacetime';
import { formatNumber } from 'libphonenumber-js';

import classes from './UserView.module.scss';
import { Link } from 'react-router-dom';
import Loader from '../../../components/Loader';
import constants from '../../../config/constants';
import HappinessImage from '../../../components/HappinessImage';
import defaulgHappinessImage from '../../../assets/images/req-img.png';

export default class ViewHappiness extends Component {
  state = {
    loading: true,
    happiness: {},
  };

  async componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (!id) this.goBack();

    try {
      const happiness = await getHappiness(id);
      this.setState({ happiness, loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  navigateToEdit = (id) => {
    this.props.history.push(`/${constants.adminPath}/happinesses/${id}/update`);
  };

  render() {
    const { loading, happiness } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <div className={classes.root}>
        <Link to={`/${constants.adminPath}/happinesses`}>
          <p className={classes.link}>Back to all help requsts</p>
        </Link>
        <div className={classes.content}>
          <div className={classes.description}>
            <div className={classes.leftDiv}>
              <HappinessImage
                width="275"
                height="146"
                alt={happiness.title}
                src={
                  happiness.photos && happiness.photos.length > 0
                    ? happiness.photos[0].img
                    : defaulgHappinessImage
                }
              />
              <span>
                {' '}
                {happiness.execute_date
                  ? spacetime(new Date(happiness.execute_date)).format(
                      'nice-day'
                    )
                  : 'NA'}
              </span>
            </div>
            <div className={classes.details}>
              <div className={classes.title}>{happiness.title}</div>
              <div className={classes.fields}>
                <div className={classes.FieldHead}>
                  <p> Request date, time :</p>
                  {happiness.created_at
                    ? spacetime(new Date(happiness.created_at)).format(
                        'nice-day'
                      )
                    : 'NA'}
                </div>
                <div className={classes.FieldHead}>
                  <p>Posted by:</p>
                  {happiness.creator
                    ? happiness.creator.name
                      ? happiness.creator.name
                      : happiness.creator.mobile
                    : 'NA'}
                </div>
                <div className={classes.FieldHead}>
                  <p>Help to:</p>
                  {happiness.help_to ? happiness.help_to.name : 'NA'}
                  <span className={classes.phoneNumber}>
                    {happiness.help_to && happiness.help_to.mobile
                      ? formatNumber(happiness.help_to.mobile, 'INTERNATIONAL')
                      : ''}
                  </span>
                </div>
                <div className={classes.FieldHead}>
                  <p>Recommend to:</p>
                  {happiness.assignees && happiness.assignees.length > 0
                    ? happiness.assignees[0].name
                    : 'All'}
                  <span className={classes.phoneNumber}>
                    {happiness.assignees && happiness.assignees.length > 0
                      ? formatNumber(
                          happiness.assignees[0].mobile,
                          'INTERNATIONAL'
                        )
                      : ''}
                  </span>
                </div>
              </div>
              <div className={classes.FieldHead}>
                <p>Address: </p>
                {happiness.address ? happiness.address : 'NA'}
              </div>
              <div className={classes.FieldHead}>
                <p>Description: </p>
                {happiness.description ? happiness.description : 'NA'}
              </div>
              <div className={classes.FieldHead}>
                <p>Status: </p>
                {happiness.completes && happiness.completes.length > 0
                  ? `Completed ${happiness.completes.length}`
                  : 'Open'}
              </div>
            </div>
          </div>

          <div className={classes.BtnDiv}>
            <button
              className={
                happiness.is_enable ? classes.enable : classes.disabled
              }
              type="button"
              disabled={happiness.is_enable}
            >
              Enable
            </button>
            <button
              className={
                happiness.is_enable ? classes.disabled : classes.disable
              }
              type="button"
              disabled={happiness.is_enable}
            >
              Disable
            </button>
          </div>
        </div>
      </div>
    );
  }
}
