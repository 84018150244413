import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

const propTypes = {
  isOpen: PropTypes.bool,
};

const defaultProps = {
  isOpen: false,
};

const Modal = ({ children, isOpen }) => {
  const customStyles = {
    content: {
      width: 'auto',
      height: 'auto',
      top: '60%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-45%',
      transform: 'translate(-50%, -50%)',
      padding: '30px 53px 30px 53px',
      borderRadius: '2px',
      fontSize: '25px',
      textAlign: 'center',
      overflow: 'unset',
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgb(0,0,0,0.8)',
      opacity: 1,
      zIndex: 10,
    },
  };
  ReactModal.setAppElement('#root');

  return (
    <ReactModal isOpen={isOpen} style={customStyles}>
      {children}
    </ReactModal>
  );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;
export default Modal;
