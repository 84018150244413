import React, { Component } from 'react';
import {
  updateHappiness,
  getHappiness,
  updateTaskVisibility,
} from '../../../utils/api_calls';
import spacetime from 'spacetime';
import classes from './AdminView.module.scss';
import { Link } from 'react-router-dom';
import Loader from '../../../components/Loader';
import Button from '../../../components/Button';
import constants from '../../../config/constants';
import HappinessImage from '../../../components/HappinessImage';
import defaulgHappinessImage from '../../../assets/images/req-img.png';
import ReactModal from 'react-modal';
import EditHappiness from '../../../components/Happiness/EditHappiness';

const customStyles = {
  content: {
    width: 'auto',
    height: 'auto',
    top: '60%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-45%',
    transform: 'translate(-50%, -50%)',
    padding: '30px 53px 30px 53px',
    borderRadius: '2px',
    fontSize: '25px',
    textAlign: 'center',
    overflow: 'unset',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#000',
    opacity: 0.9,
    zIndex: 10,
  },
};
ReactModal.setAppElement('#root');

export default class ViewHappiness extends Component {
  state = {
    loading: true,
    happiness: {},
  };

  async componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (!id) this.goBack();

    try {
      const happiness = await getHappiness(id);
      happiness.execute_date = happiness.execute_date
        ? new Date(happiness.execute_date)
        : null;

      happiness.image =
        happiness.photos && happiness.photos.length > 0
          ? happiness.photos[0].img
          : null;
      this.setState({ happiness, loading: false });
    } catch (e) {
      this.goBack();
      this.setState({ loading: false });
    }
  }

  updateTaskStatus = async (happinessItem) => {
    const happinessResponse = await updateTaskVisibility(happinessItem._id);
    const updatedTask = happinessResponse.data.task;
    this.setState({ happiness: updatedTask });
  };

  onEnableTask = (event) => {
    const { happiness } = this.state;
    event.stopPropagation();
    if (!happiness.is_enable) {
      this.updateTaskStatus(happiness);
    }
  };

  onDisableTask = async (event) => {
    const { happiness } = this.state;
    event.stopPropagation();
    if (happiness.is_enable) {
      this.updateTaskStatus(happiness);
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { loading, happiness } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <div className={classes.root}>
        <Link to={`/${constants.adminPath}/happinesses`}>
          <p className={classes.link}>Back to all help requsts</p>
        </Link>
        <div className={classes.content}>
          <div className={classes.description}>
            <HappinessImage
              width="275"
              height="146"
              alt={happiness.title}
              src={
                happiness.photos && happiness.photos.length > 0
                  ? happiness.photos[0].img
                  : defaulgHappinessImage
              }
            />
            <div className={classes.details}>
              <div className={classes.title}>{happiness.title}</div>

              <div className={classes.date}>
                <p>Date, time :</p>
                {happiness.execute_date
                  ? spacetime(new Date(happiness.execute_date)).format(
                      'nice-day'
                    )
                  : 'NA'}
              </div>
              <Button className={classes.Btn} onClick={this.handleOpenModal}>
                Edit
              </Button>
            </div>
          </div>
          <div>
            <ReactModal
              isOpen={this.state.showModal}
              contentLabel="Minimal Modal Example"
              style={customStyles}
            >
              <div className={classes.editPopup}>
                <EditHappiness
                  submitHappiness={updateHappiness}
                  {...happiness}
                  isEdit={true}
                />
              </div>
            </ReactModal>
          </div>

          <div className={classes.reqbtnDiv}>
            <button
              onClick={this.onEnableTask}
              className={[
                classes.commonButton,
                happiness.is_enable ? classes.enable : '',
              ].join(' ')}
              disabled={happiness.is_enable}
              type="button"
            >
              Enable
            </button>
            <button
              onClick={this.onDisableTask}
              className={[
                classes.commonButton,
                !happiness.is_enable ? classes.disable : '',
              ].join(' ')}
              disabled={!happiness.is_enable}
              type="button"
            >
              Disable
            </button>
          </div>
        </div>
      </div>
    );
  }
}
