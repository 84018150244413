const URL = 'https://api.happinessfactory.app' 
//const URL = 'http://0.0.0.0:3030'
const VERSION = '/api/v1'
const ADMIN = '/admin'

export default {
	user: {
		profile: `${URL}${VERSION}/user/profile`,
		getAll: `${URL}${VERSION}${ADMIN}/users`,
		updateStatus: `${URL}${VERSION}${ADMIN}/user/updateStatus`,
	},
	auth: {
		login: `${URL}${VERSION}${ADMIN}/login`,
		verifyEmail: `${URL}${VERSION}/auth/verify-email`,
	},
	happiness: {
		getAll: `${URL}${VERSION}${ADMIN}/tasks`,
		add: `${URL}${VERSION}${ADMIN}/tasks`,
		edit: `${URL}${VERSION}${ADMIN}/tasks`,
		uploadTask: `${URL}${VERSION}${ADMIN}/uploadTask`,
		updateStatus: `${URL}${VERSION}${ADMIN}/tasks/availability`,
	},
	notification: {
		getAll: `${URL}${VERSION}${ADMIN}/notifications`,
		markRead: `${URL}${VERSION}${ADMIN}/notifications/read`,
	},
	adminConfig: {
		getDyanimicHappinessOptions: `${URL}${VERSION}${ADMIN}/dyanimicHappinessOptions`,
		notificationSetting: `${URL}${VERSION}${ADMIN}/notificationSettings`,
		happinessSetting: `${URL}${VERSION}${ADMIN}/happinessSettings`,
	}
}
