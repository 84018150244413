/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import Button from '../../Button';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import { CircularProgressbar } from 'react-circular-progressbar';
import Input from '../../Input';
import { compressHappinessImage } from '../../../utils';
import Loader from '../../Loader';
import HappinessImage from '../../HappinessImage';
import constants from '../../../config/constants';
import classes from './CreateEditHappiness.module.scss';
import defaultImage from '../../../assets/images/help-banner.png';
import excelSVGImage from '../../../assets/images/excel.svg';
import { getParsedMsg } from '../../../utils/api_calls';
import Close from '../../../assets/images/close.svg';
import Modal from '../../Modal';
import apis from '../../../config/apis';
import { NotificationManager } from 'react-notifications';
import { getToken } from '../../../utils/auth';

const CancelToken = axios.CancelToken;
var cancel;

class CreateEditHappiness extends Component {
  constructor(props) {
    super(props);

    this.isEdit = props.isEdit;
    this.state = {
      users: [],
      uploadModel: false,
      percentCompleted: 0,
      _id: props._id ? props._id : null,
      title: props.title ? props.title : '',
      image: props.image ? props.image : null,
      loading: false,
      submitLoading: false,
      errors: {},
      scale: 3,
    };
  }

  validate = () => {
    const errors = {};
    const { title } = this.state;
    let isError = false;
    if (title.trim().length <= 0) {
      errors.title = 'Full title is required';
      isError = true;
    }

    this.setState({ errors });
    return isError;
  };

  uploadMultipleHappiness = async (file) => {
    try {
      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.setState({
            percentCompleted: percentCompleted,
          });
        },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
        headers: { Authorization: getToken() },
      };
      const formData = new FormData();
      formData.append('file', file);

      axios
        .post(apis.happiness.uploadTask, formData, config)
        .then((res) => {
          this.handleUplaodResponse(res, 'success');
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            this.handleUplaodResponse({ data: err }, 'error');
          } else {
            this.handleUplaodResponse(err.response, 'error');
          }
        });
    } catch (error) {
      this.handleUplaodResponse(error.response, 'error');
    }
  };

  handleUplaodResponse = (response, notificatioType) => {
    NotificationManager[notificatioType](getParsedMsg(response.data));
    this.handleUploadModalClose();
    this.setState({
      percentCompleted: 0,
    });
  };

  handleDrop = (dropped) => {
    this.setState({ image: dropped[0] });
  };

  handleHappinessExcelDrop = (dropped) => {
    if (dropped[0]) {
      this.setState({ uploadModel: true });
      this.uploadMultipleHappiness(dropped[0]);
    }
  };

  handleUploadModalClose = () => {
    this.setState({ uploadModel: false });
  };

  clearInput = () => {
    this.setState({
      title: '',
      image: null,
    });
  };

  submit = async (e) => {
    e.preventDefault();
    if (this.validate()) {
      return Promise.resolve();
    }
    const data = { ...this.state };
    this.setState({ submitLoading: true });
    if (
      this.imageRef &&
      this.imageRef.props.image &&
      typeof this.imageRef.props.image !== 'string'
    ) {
      const img = await fetch(this.imageRef.getImage().toDataURL());
      let filename = this.state.image.name;
      if (filename.includes('.png')) {
        filename = filename.replace(/.png/g, '.jpeg');
      }
      const blob = await img.blob();
      data.image = await compressHappinessImage(blob, filename);
    } else {
      data.image = null;
    }

    try {
      await this.props.submitHappiness(data, this.state._id);
      if (!this.isEdit) {
        this.clearInput();
      }
      this.setState({ submitLoading: false });
      this.props.history.push(`/${constants.adminPath}/happinesses`);
    } catch (e) {
      this.setState({ submitLoading: false });
    }
  };

  cancelClickHandler = () => {
    this.props.history.push(`/${constants.adminPath}/happinesses`);
  };

  cancelMutlipleTaskUploadModalClick = () => {
    cancel('Import data gets canceled.');
  };

  setImageRef = (imageRef) => (this.imageRef = imageRef);

  removeImage = () => this.setState({ image: null });

  setScale = (e) => this.setState({ scale: e.target.value });

  handleInputChange = (key, value) => {
    const errors = { ...this.state.errors };
    errors[key] = '';
    this.setState({
      [key]: value,
      errors,
    });
  };

  render() {
    const {
      loading,
      submitLoading,
      title,
      image,
      errors,
      percentCompleted,
    } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <div className={classes.adminSmallCont}>
        <div className={classes.addMultipleHappinessBlock}>
          <h3 className={classes.pageTitle}>Add Multiple Happiness</h3>
          <Dropzone
            // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onDrop={this.handleHappinessExcelDrop}
            multiple={false}
            className={classes.multipleHappinessFile}
          >
            <div className={classes.multipleDiv}>
              <HappinessImage
                width="52"
                height="46"
                alt={title}
                src={excelSVGImage}
              />
              <h4>Drag file here / Upload file manually</h4>
              <button
                type="button"
                className={classes.multiplHappinessBrowseButton}
              >
                Add Multiple Happiness
              </button>
            </div>
          </Dropzone>
        </div>
        <div className={classes.happinessSeperation}></div>
        <div className={classes.addhappinessBlock}>
          <h3 className={classes.pageTitle}>Add Happiness</h3>
          <form onSubmit={this.submit}>
            {!image && (
              <Dropzone
                onDrop={this.handleDrop}
                multiple={false}
                accept="image/*"
                className={classes.happinessImage}
              >
                <HappinessImage
                  width="695"
                  height="150"
                  alt={title}
                  src={defaultImage}
                />
                <button type="button" className={classes.browseButton}>
                  Browse from computer
                </button>
              </Dropzone>
            )}
            {image && typeof image !== 'string' && (
              <AvatarEditor
                ref={this.setImageRef}
                width={695}
                height={150}
                border={1}
                scale={0.7 * this.state.scale}
                image={image}
              />
            )}
            {image && typeof image === 'string' && (
              <div className={classes.happinessImage}>
                <HappinessImage
                  width="695"
                  height="150"
                  alt={title}
                  src={image}
                />
              </div>
            )}
            {image && typeof image !== 'string' && (
              <Input
                className={classes.inputText}
                type="range"
                value={this.state.scale}
                min="1.46"
                step="0.01"
                max="10"
                onChange={this.setScale}
              />
            )}
            {image && <Button onClick={this.removeImage}>Remove</Button>}
            <div className={classes.happinessForm}>
              <div className={classes.formField}>
                <label className={classes.formLable}>Full title *</label>
                <Input
                  className={classes.inputText}
                  type="text"
                  name="title"
                  value={title}
                  error={errors.title}
                  placeholder="title"
                  onChange={(e) =>
                    this.handleInputChange('title', e.target.value)
                  }
                />
              </div>
              <div className={[classes.btnContainer, classes.cf].join(' ')}>
                <div className={[classes.cols6].join(' ')}>
                  <Button
                    className={[classes.cancelBtn, classes.formButtons].join(
                      ' '
                    )}
                    type="button"
                    onClick={this.cancelClickHandler}
                  >
                    Cancel
                  </Button>
                </div>
                <div className={[classes.right, classes.cols6].join(' ')}>
                  <Button
                    className={[classes.saveBtn, classes.formButtons].join(' ')}
                    loading={submitLoading ? submitLoading : undefined}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div>
          <Modal isOpen={this.state.uploadModel}>
            <div className={classes.multipleHappinessUploadModal}>
              <h3 className={classes.infoText}>Adding Multiple Happiness</h3>
              <a
                className={classes.closeBtnHeader}
                onClick={this.cancelMutlipleTaskUploadModalClick}
              >
                <img className={classes.close} src={Close} alt="close" />{' '}
              </a>
              <div className={classes.progressBarLoader}>
                <CircularProgressbar
                  className={classes.progressBar}
                  value={percentCompleted}
                />
                <HappinessImage
                  className={classes.loadingImage}
                  width="52"
                  height="46"
                  alt={title}
                  src={excelSVGImage}
                />
              </div>
              <div>
                <div className={classes.loadingText}>Please wait…</div>
                <div className={classes.loadingText}>
                  System is fetching the details
                </div>
              </div>
              <div className={classes.modalButtonDiv}>
                <Button
                  className={[classes.cancelBtn, classes.formButtons].join(' ')}
                  type="button"
                  onClick={this.cancelMutlipleTaskUploadModalClick}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateEditHappiness);
