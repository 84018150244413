import React from 'react';
import spacetime from 'spacetime';
import HappinessImage from '../../HappinessImage';
import classes from './AdminItem.module.scss';
import defaulgHappinessImage from '../../../assets/images/req-img.png';
import Like from '../../../assets/images/like.svg';
import Dislike from '../../../assets/images/dislike.svg';

const HappinessItem = ({ happiness, navigateToView, updateTaskStatus }) => {
  const onEnableTask = (event) => {
    event.stopPropagation();
    if (!happiness.is_enable) updateTaskStatus(happiness);
  };

  const onDisableTask = (event) => {
    event.stopPropagation();
    if (happiness.is_enable) updateTaskStatus(happiness);
  };

  return (
    <div
      className={[classes.requestsDetail, classes.cf].join(' ')}
      onClick={() => navigateToView(happiness._id)}
    >
      <div className={[classes.tableRow, classes.helpDetails].join(' ')}>
        <div className={classes.requestsInfo}>
          <HappinessImage
            width="130"
            height="70"
            alt={happiness.title}
            src={
              happiness.photos && happiness.photos.length > 0
                ? happiness.photos[0].img
                : defaulgHappinessImage
            }
          />
        </div>
        <p className={classes.requestsTitle}>
          {happiness.title ? happiness.title : 'NA'}
        </p>
      </div>
      <div className={[classes.tableRow, classes.like].join(' ')}>
        <div>
          <div className={classes.likeDiv}>
            <button className={classes.likeBtn}>
              <img className={classes.likeIcon} src={Like} alt="like" />
            </button>
            <p className={classes.likeCount}>{happiness.like_count || 0}</p>
          </div>
          <div className={classes.likeDiv}>
            <button className={classes.disLikeBtn}>
              <img className={classes.likeIcon} src={Dislike} alt="like" />
            </button>
            <div>
              <p className={classes.disLikeCount}>
                {happiness.dislike_count || 0}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={[classes.tableRow, classes.requestTime].join(' ')}>
        {happiness.execute_date
          ? spacetime(new Date(happiness.execute_date)).format('nice-day')
          : 'NA'}
      </div>
      <div className={[classes.tableRow, classes.reqbtnDiv].join(' ')}>
        <button
          onClick={onEnableTask}
          className={[
            classes.commonButton,
            happiness.is_enable ? classes.enable : '',
          ].join(' ')}
          disabled={happiness.is_enable}
          type="button"
        >
          Enable
        </button>
        <button
          onClick={onDisableTask}
          className={[
            classes.commonButton,
            !happiness.is_enable ? classes.disable : '',
          ].join(' ')}
          disabled={!happiness.is_enable}
          type="button"
        >
          Disable
        </button>
      </div>
    </div>
  );
};

export default HappinessItem;
