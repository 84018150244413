import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getUserDetails } from '../../utils/auth';
import constants from '../../config/constants';
import classes from './Header.module.scss';
import happinessLogo from '../../assets/images/happiness-logo.svg';
import notificationIcon from '../../assets/images/notifications.svg';

class Header extends Component {
  state = {
    user: getUserDetails()
  };

  render() {
    const { user } = this.state;

    return (
      <header className={[classes.head, classes.cf].join(' ')}>
        <h1 className={classes.titleText}>
          <Link
            className={classes.titleLink}
            to={`/${constants.adminPath}/`}
            title="Happiness Factory"
          >
            <img
              width="199"
              height="30"
              alt="Happiness Factory"
              src={happinessLogo}
            />
          </Link>
        </h1>
        <div className={classes.headerRight}>
          <div className={classes.addAction}>
            <Link
              className={classes.addActionLink}
              title="Add Help Request"
              to={`/${constants.adminPath}/happinesses/new`}
            >
              + Add Help Request
            </Link>
          </div>
          <div className={classes.notification}>
            <Link
              className={classes.notificationLink}
              to={`/${constants.adminPath}/notifications`}
            >
              <img
                width="18"
                height="22"
                alt="Notifcations"
                src={notificationIcon}
              />
              <span className={classes.newNoti}></span>
            </Link>
          </div>
          <div className={classes.userDisaply}>
            <p className={classes.userName}>{user && user.email}</p>
          </div>
          <div>
            <Link
              className={classes.logoutLink}
              to={`/${constants.adminPath}/logout`}
            >
              Logout
            </Link>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
