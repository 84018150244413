import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import constants from './config/constants';
import Layout from './components/Layout';
import Authentication from './components/Authentication';
import Login from './pages/Auth/Login';
import Dashboard from './pages/Dashboard';
import CreateHappiness from './pages/Happiness/Create';
import UpdateHappiness from './pages/Happiness/Update';
import ListHappiness from './pages/Happiness/List';
import AdminViewHappiness from './pages/Happiness/AdminView';
import ListUsers from './pages/User/List';
import ViewUser from './pages/User/View';
import Logout from './pages/Auth/Logout';
import UnderConstruction from './pages/UnderConstruction';
import VerifyEmail from './pages/VerifyEmail';
import RedirectMobile from './pages/RedirectMobile';
import Settings from './pages/Settings';
import UserViewHappiness from './pages/Happiness/UserView';

const RouterComponent = () => {
  return (
    <Switch>
      <Route exact path={`/verify-email`} component={VerifyEmail} />
      <Route exact path={`/redirect-mobile`} component={RedirectMobile} />
      <Route exact path={`/${constants.adminPath}/login`} component={Login} />
      <Route exact path={`/`} component={UnderConstruction} />
      <Layout>
        <Authentication>
          <Route exact path={`/${constants.adminPath}`} component={Dashboard} />
          <Route
            exact
            path={`/${constants.adminPath}/happinesses`}
            component={ListHappiness}
          />
          <Route
            exact
            path={`/${constants.adminPath}/happinesses/:id/update`}
            component={UpdateHappiness}
          />
          <Route
            exact
            path={`/${constants.adminPath}/happinesses/:id/adminview`}
            component={AdminViewHappiness}
          />
          <Route
            exact
            path={`/${constants.adminPath}/happinesses/:id/userview`}
            component={UserViewHappiness}
          />
          <Route
            exact
            path={`/${constants.adminPath}/happinesses/new`}
            component={CreateHappiness}
          />
          <Route
            exact
            path={`/${constants.adminPath}/users`}
            component={ListUsers}
          />
          <Route
            exact
            path={`/${constants.adminPath}/users/:id/view`}
            component={ViewUser}
          />
          <Route
            exact
            path={`/${constants.adminPath}/logout`}
            component={Logout}
          />
          <Route
            exact
            path={`/${constants.adminPath}/settings`}
            component={Settings}
          />
        </Authentication>
      </Layout>
      <Route component={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default RouterComponent;
