import React from "react";
import spacetime from "spacetime";
import HappinessImage from "../../HappinessImage";
import classes from "./TopHappinessItem.module.scss";
import defaulgHappinessImage from "../../../assets/images/req-img.png";
import Like from "../../../assets/images/like.svg";

const TopHappinessItem = ({ happiness, index }) => {
  return (
    <div
      className={[classes.requestsDetail, classes.cf].join(" ")}
    >
       <div className={[classes.tableRow, classes.topNumber].join(" ")}>
        {index}
      </div>
      <div className={[classes.tableRow, classes.helpDetails].join(" ")}>
        <div className={classes.requestsInfo}>
          <HappinessImage
            width="130"
            height="70"
            alt={happiness.title}
            src={
              happiness.photos && happiness.photos.length > 0
                ? happiness.photos[0].img
                : defaulgHappinessImage
            }
          />
        </div>
        <p className={classes.requestsTitle}>
          {happiness.title ? happiness.title : "NA"}
        </p>
      </div>
      <div className={[classes.tableRow, classes.requestTime].join(" ")}>
        {happiness.execute_date
          ? spacetime(new Date(happiness.execute_date)).format("nice-day")
          : "NA"}
      </div>

      <div className={[classes.tableRow, classes.deliver].join(" ")}>{1000}</div>
      <div className={[classes.tableRow, classes.completed].join(" ")}>
        {1000}
      </div>
      <div className={[classes.tableRow, classes.like].join(" ")}>
        <div>
          <div className={classes.likeDiv}>
            <button className={classes.likeBtn}>
              <img className={classes.likeIcon} src={Like} alt="like" />
            </button>
            <p className={classes.likeCount}>100</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHappinessItem;
