import React, { Component } from 'react';
import { verifyEmail } from '../../utils/api_calls';
import { parseQuery } from '../../utils';
import classes from './VerifyEmail.module.scss';

export default class VerifyEmail extends Component {
  state = {
    loading: true,
    response: ''
  };

  async componentDidMount() {
    document.body.classList.add('loginBlock');
    const payload = parseQuery(this.props.location.search);
    try {
      const data = await verifyEmail(payload);
      if (data && data.msg) {
        this.setState({ response: data.msg });
      }
    } catch (e) {
      if (e && e.msg) {
        this.setState({ response: e.msg });
      }
    }
    this.setState({ loading: false });
  }

  render() {
    const { loading, response } = this.state;
    return (
      <div className={classes.verifyEmailContainer}>
        <div>{loading ? 'Verifing Email....' : response}</div>
      </div>
    );
  }
}
