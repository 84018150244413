import React, { Component } from 'react';
import CreateEditHappiness from '../../../components/Happiness/CreateEditOld';
import { updateHappiness, getHappiness } from '../../../utils/api_calls';
import Loader from '../../../components/Loader';

export default class UpdateHappiness extends Component {
  state = {
    happiness: {},
    loading: true,
  };

  goBack = () => {
    this.props.history.goBack();
  };

  async componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (!id) this.goBack();

    try {
      const happiness = await getHappiness(id);
      happiness.execute_date = happiness.execute_date
        ? new Date(happiness.execute_date)
        : null;
      happiness.recommend_to =
        happiness.assignees && happiness.assignees.length > 0
          ? happiness.assignees[0].mobile
          : null;

      happiness.help_to = happiness.help_to ? happiness.help_to.mobile : null;

      happiness.image =
        happiness.photos && happiness.photos.length > 0
          ? happiness.photos[0].img
          : null;
      this.setState({ happiness, loading: false });
    } catch (e) {
      this.goBack();
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading, happiness } = this.state;
    if (loading) {
      return <Loader />;
    }

    return (
      <CreateEditHappiness
        submitHappiness={updateHappiness}
        {...happiness}
        isEdit={true}
      />
    );
  }
}
