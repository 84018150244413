import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Loader from '../Loader';
import { getToken } from '../../utils/auth';

export default class Authentication extends Component {
  state = {
    loading: true,
    isLoggedin: false
  };

  async componentDidMount() {
    const token = getToken();
    document.body.classList.remove('loginBlock');
    if (token) {
      this.setState({ isLoggedin: true, loading: false });
    } else {
      this.setState({ isLoggedin: false, loading: false });
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (!this.state.isLoggedin) {
      return (
        <Redirect
          to={{
            pathname: `/`,
            state: { from: this.props.location }
          }}
        />
      );
    }

    return <div>{this.props.children}</div>;
  }
}
