import React from "react";
import classes from "./Dashboard.module.scss";
import { Bar, Line } from "react-chartjs-2";
import { getHappinesses } from "../../utils/api_calls";
import HappinessList from "../../components/Happiness/TopHappinessList/";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      happinesses: [],
      loading: true,
      is_more: false,
      page_size: 20,
      loadingMore: false,
      userData: {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
          {
            label: "Android",
            backgroundColor: "#7F2C00",
            stack: 1,
            data: [30, 50, 60, 50, 80, 90, 100],
          },

          {
            label: "iOS",
            backgroundColor: "#FF5900",
            stack: 1,
            data: [140, 90, 80, 70, 60, 50, 40],
          },
          {
            label: "Android",
            backgroundColor: "#0C6B35",
            stack: 2,
            data: [10, 30, 50, 70, 90, 110, 120],
          },

          {
            label: "iOS",
            backgroundColor: "#19D86C",
            stack: 2,
            data: [130, 110, 90, 70, 50, 30, 10],
          },
        ],
      },
      happinessData: {
        labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
        datasets: [
          {
            label: "Admin",
            backgroundColor: "#7F2C00",
            stack: 1,
            data: [10, 20, 40, 60, 80, 65, 70],
          },
          {
            label: "User",
            backgroundColor: "#FF5900",
            stack: 1,
            data: [30, 230, 400, 69, 80, 345, 70],
          },
          {
            label: "Admin",
            backgroundColor: "#0C6B35",
            stack: 2,
            data: [30, 340, 400, 60, 60, 50, 70],
          },
          {
            label: "User",
            backgroundColor: "#19D86C",
            stack: 2,
            data: [30, 200, 40, 60, 80, 670, 70],
          },
        ],
      },
      notificationData: {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
          {
            label: "Daily suggestions",
            backgroundColor: "#7F2C00",
            stack: 1,
            data: [10, 20, 40, 65, 80, 65, 70],
          },
          {
            label: "Reminder to complete",
            backgroundColor: "#FF5900",
            stack: 1,
            data: [30, 23, 40, 69, 80, 34, 70],
          },
          {
            label: "Friend Challenge",
            backgroundColor: "rgb(255,186,149)",
            stack: 1,
            data: [30, 34, 40, 60, 60, 50, 70],
          },
          {
            label: "Daily suggestions",
            backgroundColor: "#0C6B35",
            stack: 2,
            data: [30, 20, 40, 60, 8, 67, 70],
          },
          {
            label: "Reminder to complete",
            backgroundColor: "#19D86C",
            stack: 2,
            data: [30, 20, 40, 60, 80, 67, 70],
          },
          {
            label: "Friend Challenge",
            backgroundColor: "#7AFFB4",
            stack: 2,
            data: [30, 20, 40, 60, 80, 67, 70],
          },
        ],
      },
      creationData: {
        type: "line",
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
          {
            data: [
              {
                t: "2015-03-15T13:03:00Z",
                y: 1,
              },
              {
                t: "2015-03-25T13:02:00Z",
                y: 3,
              },
              {
                t: "2015-04-25T14:12:00Z",
                y: 2,
              },
              {
                t: "2015-04-25T14:12:00Z",
                y: 4,
              },
            ],
            backgroundColor: "#FF5900",
            borderColor: "#FF5900",
            borderWidth: 1,
            borderDash: [3, 10],
            fill: false,
          },
          {
            data: [
              {
                t: "2015-03-15T13:03:00Z",
                y: 12,
              },
              {
                t: "2015-03-25T13:02:00Z",
                y: 21,
              },
              {
                t: "2015-04-25T14:12:00Z",
                y: 2,
              },
              {
                t: "2015-04-25T14:12:00Z",
                y: 4,
              },
            ],
            backgroundColor: "#19D86C",
            borderColor: "#19D86C",
            borderWidth: 1,
            borderDash: [3, 10],
            fill: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.fetchHappiness();
  }

  datasetKeyProvider = () => {
    return Math.random();
  };

  fetchHappiness = async () => {
    const happinesses = await getHappinesses(1);
    this.setState({
      happinesses: happinesses.data,
      loading: false,
      is_more: happinesses.is_more,
      page_size: happinesses.page_size,
    });
  };

  render() {
    const { happinesses, is_more, loadingMore } = this.state;

    const tooltipCustom = {
      enabled: false,

      custom: function (tooltipModel) {
        var tooltipEl = document.getElementById("chartjs-tooltip");
        const data = this._data.datasets;

        tooltipEl.innerHTML = "<table></table>";
        if (!tooltipEl) {
          tooltipEl = document.createElement("div");
          tooltipEl.id = "chartjs-tooltip";
          document.body.appendChild(tooltipEl);
        }
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
        if (tooltipModel.body) {
          var bodyLines = data.map((el) => {
            const label = el.label;
            const colors = el.backgroundColor;
            const data = el.data;
            const stack = el.stack;
            return { label, colors, data, stack };
          });

          var innerHtml = "<thead>";

          innerHtml += `<tbody style=""><tr style="display:flex;flex-direction:column; height:${this._options.height}; width:${this._options.width}; flex-wrap:wrap">`;
          let sum1 = 0;

          bodyLines.forEach(function (body) {
            if (body.stack === 1) {
              sum1 += body.data[tooltipModel.dataPoints[0].index];

              let style = `height: 10px ; width:10px; border-radius : 50%; margin-right :10px`;
              innerHtml +=
                `<td style="display:flex;"><div style="${style} ; background : ${body.colors} "></div>` +
                body.data[tooltipModel.dataPoints[0].index] +
                " " +
                body.label +
                "</td>";
            }
          });
          innerHtml += `<td style="display:flex;justify-content: flex-start"> = ${sum1}</td>`;

          let sum2 = 0;
          bodyLines.forEach(function (body, i) {
            if (body.stack === 2) {
              sum2 += body.data[tooltipModel.dataPoints[0].index];
              let style = `height: 10px ; width:10px; border-radius : 50%; margin-right :10px`;
              innerHtml +=
                `<td style="display:flex; heigth:200px;"><div style="${style} ; background : ${body.colors} "></div>` +
                body.data[tooltipModel.dataPoints[0].index] +
                " " +
                body.label +
                "</td>";
            }
          });
          innerHtml += `<td style="display:flex;justify-content: flex-start"> = ${sum2}</td>`;
          innerHtml += "</tr></tbody>";

          var tableRoot = tooltipEl.querySelector("table");
          tableRoot.innerHTML = innerHtml;
        }

        var position = this._chart.canvas.getBoundingClientRect();

        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = "absolute";
        tooltipEl.style.left =
          position.left + window.pageXOffset + tooltipModel.caretX + "px";
        tooltipEl.style.top =
          position.top + window.pageYOffset + tooltipModel.caretY + "px";
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding =
          tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
        tooltipEl.style.pointerEvents = "none";
      },
    };

    const getDefaultBarOption = ({
      barPercentage,
      categoryPercentage,
      height,
      width,
    }) => {
      return {
        responsive: true,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              barPercentage,
              categoryPercentage,
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
        },
        tooltips: { ...tooltipCustom, height, width },
      };
    };

    const userOptions = getDefaultBarOption({
      barPercentage: 0.65,
      categoryPercentage: 0.75,
      height: "100px",
      width: "300px",
    });

    const happinessOptions = getDefaultBarOption({
      barPercentage: 0.5,
      categoryPercentage: 0.6,
      height: "100px",
      width: "300px",
    });

    const notificationOption = getDefaultBarOption({
      barPercentage: 0.65,
      categoryPercentage: 0.75,
      height: "130px",
      width: "400px",
    });

    const creationOption = {
      responsive: true,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
        yAxes: [{ stacked: true }],
      },
      tooltips: {
      enabled: false,

      custom: function (tooltipModel) {
        var tooltipEl = document.getElementById("chartjs-tooltip");
        const data = this._data.datasets;

        tooltipEl.innerHTML = "<table></table>";
        if (!tooltipEl) {
          tooltipEl = document.createElement("div");
          tooltipEl.id = "chartjs-tooltip";
          document.body.appendChild(tooltipEl);
        }
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
        if (tooltipModel.body) {
          var bodyLines = data.map((el) => {
            const label = el.label;
            const colors = el.backgroundColor;
            const data = el.data;
            const stack = el.stack;
            return { label, colors, data, stack };
          });
          var innerHtml = "<thead>";

          innerHtml += `<tbody style=""><tr style="display:flex;flex-direction:column; height:60px; width:50px; flex-wrap:wrap">`;


          bodyLines.forEach(function (body) {
              let style = `height: 10px ; width:10px; border-radius : 50%; margin-right :10px`;
              innerHtml +=
                `<td style="display:flex;"><div style="${style} ; background : ${body.colors} "></div>` +
                body.data[tooltipModel.dataPoints[0].index].y +
                "</td>";
          });

          innerHtml += "</tr></tbody>";

          var tableRoot = tooltipEl.querySelector("table");
          tableRoot.innerHTML = innerHtml;
        }

        var position = this._chart.canvas.getBoundingClientRect();

        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = "absolute";
        tooltipEl.style.left =
          position.left + window.pageXOffset + tooltipModel.caretX + "px";
        tooltipEl.style.top =
          position.top + window.pageYOffset + tooltipModel.caretY + "px";
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding =
          tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
        tooltipEl.style.pointerEvents = "none";
      },
    }
    };
    return (
      <>
        <div className={classes.FirstRow}>
          <div className={classes.user}>
            <div className={classes.ChartHead}>
              <h3 className={classes.ChartTitle}>Users</h3>
              <select className={classes.dropDown}>
                <option>Days</option>
              </select>
            </div>
            <Bar
              data={this.state.userData}
              options={userOptions}
              datasetKeyProvider={this.datasetKeyProvider}
            />
            <div id="chartjs-tooltip"></div>

            <div className={classes.legendDiv}>
              <div className={classes.legend}>
                <div className={classes.legendBtnNew}></div>{" "}
                <p className={classes.legendText}>New User</p>
                <div className={classes.legendBtnOld}></div>{" "}
                <p className={classes.legendText}>Active User</p>
              </div>
              <div>
                <p className={classes.legendText}>Total - 25000 User</p>
              </div>
            </div>
          </div>

          <div className={classes.line}></div>

          <div className={classes.user}>
            <div className={classes.ChartHead}>
              <h3 className={classes.ChartTitle}>Happiness</h3>
              <select className={classes.dropDown}>
                <option>Week</option>
              </select>
            </div>
            <Bar
              data={this.state.happinessData}
              options={happinessOptions}
              datasetKeyProvider={this.datasetKeyProvider}
            />
            <div className={classes.legendDiv}>
              <div className={classes.legend}>
                <div className={classes.legendBtnNew}></div>{" "}
                <p className={classes.legendHappiness}>Total Happiness</p>
                <div className={classes.legendBtnOld}></div>{" "}
                <p className={classes.legendHappiness}>Completed Happiness</p>
              </div>
              <div>
                <p className={classes.legendHappiness}>
                  Total - 25000 Happiness
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.secondRow}>
          <div className={classes.user}>
            <div className={classes.ChartHead}>
              <h3 className={classes.ChartTitle}>Notification Success</h3>
              <select className={classes.dropDown}>
                <option>Days</option>
              </select>
            </div>
            <Bar
              data={this.state.notificationData}
              options={notificationOption}
              datasetKeyProvider={this.datasetKeyProvider}
            />

            <div className={classes.legendDiv}>
              <div className={classes.legend}>
                <div className={classes.legendBtnNew}></div>{" "}
                <p className={classes.legendText}>Delivered</p>
                <div className={classes.legendBtnOld}></div>{" "}
                <p className={classes.legendText}>Tapped</p>
              </div>
              <div>
                <p className={classes.legendText}>Total - 25000 App Opened</p>
              </div>
            </div>
          </div>
          <div className={classes.line}></div>

          <div className={classes.user}>
            <div className={classes.ChartHead}>
              <h3 className={classes.ChartTitle}>HF Creation/ Completion</h3>
              <select className={classes.dropDown}>
                <option>Days</option>
              </select>
            </div>
            <Line
              data={this.state.creationData}
              options={creationOption}
              datasetKeyProvider={this.datasetKeyProvider}
            />
            <div className={classes.legendDiv}>
              <div className={classes.legend}>
                <div className={classes.legendBtnNew}></div>{" "}
                <p className={classes.legendText}>Creation Time</p>
                <div className={classes.legendBtnOld}></div>{" "}
                <p className={classes.legendText}>Completion Time</p>
              </div>
              <div>
                <p className={classes.legendText}>Total - 25000 User</p>
              </div>
            </div>
          </div>
        </div>
        <h3 className={classes.head}>Top 10 highest Rated Admin Happiness</h3>
        <table>
          <HappinessList
            happinesses={happinesses}
            is_more={is_more}
            loadingMore={loadingMore}
          />
        </table>
      </>
    );
  }
}

export default Dashboard;
